import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import server from '../api/server'
import { swalToast, swalQuestionCustom,swalError } from '../helpers/swal'

export const useClubStore = defineStore('clubStore', () => {
    const clubs = ref([])
    const current = ref({})

    const isLoading = ref(true)
    const searchInput = ref('')
    const page = ref(1)
    const itemsPerPage = ref(15)
    const orderFilter = ref('asc')

    const paginated = computed(() => {
        let items = []

        if (searchInput.value.length > 2) {
            items = clubs.value.filter((element) => {
                return element.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0
            })
        } else {
            items = clubs.value.slice((page.value - 1) * itemsPerPage.value, ((page.value - 1) * itemsPerPage.value) + itemsPerPage.value)
        }


        return items
    })

    async function getClubs() {
        isLoading.value = true
        try {
            const { data } = await server.get(`/clubs`)
            const { status, results } = data

            if (!status)
                return false

            clubs.value = results.data
            isLoading.value = false
        } catch (err) {
            console.log(err, 'error')
            return false
        }
    }

    async function getById(id) {
        try {
            const { data } = await server.get(`/clubs/${id}`)
            const { status, results } = data

            if (!status)
                return false

            return results.data
        } catch (err) {
            return false
        }
    }

    function sortDirection(arg) {
        orderFilter.value = arg
        clubs.value.reverse()
    }

    function nextPage() {
        if (page.value > clubs.value.length / itemsPerPage.value) return;

        page.value++
    }

    function prevPage() {
        if ((page.value - 1) < 1) return;

        page.value--
    }

    function changePage(value) {
        page.value = value
    }

    function filter(event) {
        const field = event.target.value
        clubs.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function getCurrent(id) {
        if (id != 'new') {
            current.value = clubs.value.find(el => el.id == id)
        } else {
            current.value = {
                name: '',
                logo: ''
            }
        }
    }

    async function submitForm(form) {
        try {
            const { data } = await server.post('/clubs', form)
            const { status, results, message } = data

            if (!status)
                return false

            clubs.value.unshift(results.data)
            swalToast(results.message)

        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
        return true
    }

    async function update() {
        try {
            const { data } = await server.post('/clubs/update', current.value)
            const { status, message } = data

            if (!status)
                return false

            const index = clubs.value.findIndex(item => item.id == current.value.id)
            clubs.value[index] = current.value

            swalToast(message)
        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
        return true
    }

    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar este club?", "Una vez eliminado no podrá recuperarse.", "Eliminar", "Cancelar", "warning")
        if (!confirmed) return;

        try {
            const { data } = await server.delete(`clubs/${id}`)
            const { status, message } = data

            if (!status)
                return false

            const index = clubs.value.findIndex(el => el.id == id)
            clubs.value.splice(index, 1)

            swalToast(message)
            return true
        } catch (err) {
            swalError(err.message, err.status)
            return false
        }
    }

    async function submitUser(payload) {
        try {
            const { data } = await server.post(`/clubs/${payload.type}`, payload)
            const { status, results, message } = data

            if (!status)
                return false

            const index = clubs.value.findIndex(item => item.id == current.value.id)
            clubs.value[index] = results.data
            current.value.athletes = results.data.athletes
            current.value.trainers = results.data.trainers
            swalToast(message)
        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            })
        }
        return true
    }

    async function getAthletesByClub(id) {
        try {
            const { data } = await server.get(`/clubs/${id}/athletes`)
            const { status, results } = data

            if (!status)
                return null

            return results
        } catch (err) {
            return null
        }
    }

    return {
        clubs,
        current,
        isLoading,
        itemsPerPage,
        orderFilter,
        page,
        paginated,
        searchInput,
        changePage,
        getAthletesByClub,
        destroy,
        filter,
        getById,
        getClubs,
        getCurrent,
        nextPage,
        prevPage,
        sortDirection,
        submitForm,
        submitUser,
        update,
    }
})
