import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';

import App from './App.vue'
import router from './router'

import './assets/main.css'
import './assets/auth.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'v-calendar/style.css';

const appVersion = process.env.VUE_APP_VERSION
console.group("[main.js] Version", appVersion)

/**
 * OneSignal setup and events
 */
window.OneSignal = window.OneSignal || [];

const pushPermission = localStorage.getItem('one-signal-notifications')
console.log((pushPermission) 
	? "[main.js] Notifications Push: Enabled" 
	: "[main.js] Notifications Push: Disabled") 

OneSignal.push(() => {
	OneSignal.init({
		appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
		subdomainName: (process.env.NODE_ENV !== 'production') ? 'entrna-test' : null,
		safari_web_id: "web.onesignal.auto.26f438e4-4907-4b0f-9fba-4ab15d3b5c3b",
		serviceWorkerPath: "OneSignalSDKWorker.js",
		allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
		welcomeNotification: { disable: true },
		promptOptions: {
			slidedown: {
				prompts: [
					{
						type: "push",
						autoPrompt: false,
						text: {
							actionMessage: "Queremos mostrarte notificaciones relacionadas con tu rutina de ejercicios.",
							acceptButton: "Permitir",
							cancelButton: "Cancelar",
						}
					}
				]
			},
		}
	});

	OneSignal.on('subscriptionChange', function (isSubscribed) {
		console.log("[main.js] The user's subscription state is now:", isSubscribed);
		localStorage.setItem('one-signal-notifications', isSubscribed)
	});
});

if('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(reg => {
		reg.addEventListener('updatefound', () => {
			const newSW = reg.installing;

			newSW.addEventListener('statechange', ev => {
				console.log('[main.js] New content is available; installing new version.')

				if (newSW.state === 'installed')
				  	reg.update()
			  });
		});

		reg.addEventListener('waiting', () => {
			reg.update()
			window.location.reload()
		});
	});
}

createApp(App)
	.use(createPinia())
	.use(router)
	.use(setupCalendar, {locale: 'es'})
	.component('VCalendar', Calendar)
	.component('VDatePicker', DatePicker)
	.mount('#app')


