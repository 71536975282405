<template>
    <PWAPromptComponent />
    <Header v-if="globalStore.status == 'authorised'" />
    
    <div class="w-100">
        <div class="loading-container h-100 d-flex flex-column align-items-center justify-content-center" v-if="globalStore.isLoading">
            <img src="@/assets/loading.gif" width="185" alt="Cargando...">
            <p class="text-muted">Espere un momento, por favor...</p>
        </div>

        <div :key="`loading-app-${globalStore.isLoading}`" v-else>
            <new-achievement />
            
            <router-view v-slot="{ Component }">
                <main class="content mb-0 pb-0" :style="globalStore.status == 'authorised' ? 'padding-bottom: 7rem!important' : ''">
                    <transition name="fade" appear>
                        <div :key="$route.name">  
                            <component :is="Component" />
                        </div>
                    </transition>
                </main>
            </router-view>
        </div>

        <Footer />
    </div>
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useClubStore } from "./stores/clubStore";
import { useGlobalStore } from "./stores/globalStore";
import { useSessionsStore } from "./stores/sessionsStore";
import { useUserStore } from "./stores/userStore";
import Footer from "./components/layouts/Footer.vue";
import Header from "./components/layouts/Header.vue";
import NewAchievement from "./components/achievements/NewAchievement.vue";
import PullToRefresh from 'pulltorefreshjs';
import PWAPromptComponent from "./components/common/PWAPromptComponent.vue";

const globalStore = useGlobalStore();
const sessionsStore = useSessionsStore();
const userStore = useUserStore();
const clubStore = useClubStore();

onBeforeMount(async () => {
    if (await globalStore.checkAuth()) {
        await userStore.getUsers();
        await sessionsStore.getSessions();
        await clubStore.getClubs();
        globalStore.setIsLoading(false)
    } else {
        globalStore.setIsLoading(false)
    }

    PullToRefresh.init({
        mainElement: 'body',
        shouldPullToRefresh: function(){
            return false // TODO Bajar la sensibilidad del PullToRefresh revisando el window.clientY document.getElementsByClassName("modal-backdrop").length > 0 ? false : true;
        },
        onRefresh() {
            window.location.reload();
        }
    })
});


</script>

<style scoped>
.content {
    width: 100% !important;
    height: 100%;
    min-height: 100vh;
    margin: 0;
}
.content::after {
    content: "";
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}
.loading-container {
    min-height: 100vh;
    align-items: center;
    text-align: center;
}
</style>