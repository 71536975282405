import { useGlobalStore } from "../../stores/globalStore";

const isNotAuth = async( to, from, next) => {
    const globalStore = useGlobalStore()
    const status = await globalStore.checkAuth()

    if (status){
        return next({ name: 'home' })
    }

    return next()
}

export default isNotAuth