import { useGlobalStore } from '../../stores/globalStore'
import { swalToast } from '../../helpers/swal'

const isAdmin = async( to, from, next) => {
    const globalStore = useGlobalStore()
    await globalStore.checkAuth()

    if(globalStore.status !== 'authorised'){
        swalToast("Debes iniciar sesión para acceder a esta sección", 'error')
        return next({ name: 'login' })
    }

    if(globalStore.auth?.data?.role !== 'admin'){
        swalToast("No tienes privilegios para acceder a esta sección.", 'error')
        return next({ name: 'home' })
    }

    return next()
}

export default isAdmin