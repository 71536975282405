import isAdmin from './guards/isAdmin'
import isTrainer from './guards/isTrainer'

export default [
    
    {
        path: '/admin/users',
        name: 'users',
        beforeEnter: [isTrainer],
        component: () => import('../views/UsersView.vue')  
    },
    {
        path: '/admin/users/:id',
        name: 'users-edit',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleUserView.vue'),
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/sessions',
        name: 'sessions',
        beforeEnter: [isTrainer],
        component: () => import('../views/SessionsView.vue')
    },
    {
        path: '/admin/sessions/:id',
        name: 'sessions-edit',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleSessionView.vue'),
        props: (route) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/sessions/attach/:id',
        name: 'sessions-attach',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleAttachSessionView.vue'),
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/club/:id/trainer',
        name: 'trainer-club',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleTrainerClubView.vue'),
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/clubs',
        name: 'clubs',
        beforeEnter: [isAdmin],
        component: () => import('../views/ClubsView.vue')
    },
    {
        path: '/admin/clubs/:id',
        name: 'clubs-edit',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleClubView.vue'),
        props: (route) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/goals',
        name: 'goals',
        beforeEnter: [isTrainer],
        component: () => import('../views/GoalsView.vue')
    },
    {
        path: '/admin/goals/:id',
        name: 'goals-edit',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleGoalView.vue'),
        props: (route) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/achievements',
        name: 'achievements',
        beforeEnter: [isTrainer],
        component: () => import('../views/AchievementsView.vue')
    },
    {
        path: '/admin/achievements/:id',
        name: 'achievements-edit',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleAchievementView.vue'),
        props: (route) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/tests',
        name: 'tests',
        beforeEnter: [isTrainer],
        component: () => import('../views/TestsView.vue')
    },
    {
        path: '/admin/tests/:id',
        name: 'tests-edit',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleTestView.vue'),
        props: (route) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/admin/notifications',
        name: 'notifications',
        beforeEnter: [isAdmin],
        component: () => import('../views/NotificationsView.vue')
    },
    {
        path: '/admin/notifications/:id',
        name: 'notifications-edit',
        beforeEnter: [isTrainer],
        component: () => import('../views/details/SingleNotificationView.vue'),
        props: (route) => {
            return {
                id: route.params.id
            }
        }
    },
]
