import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import server from '../api/server'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import router from '@/router'


export const useUserStore = defineStore('userStore', () => {
    const users = ref([])
    const currentUser = ref({})
    const searchInput = ref('')
    const orderFilter = ref('asc')

    const page = ref(1)
    const itemsPerPage = ref(15)
    const planningUser = ref(false)

    async function getUsers() {
        try {
            const { data } = await server.get(`/users`)
            const { status, results } = data

            if (!status) {
                console.log(err, 'error')
                return false
            }

            users.value = results.data.reverse()

            return users.value
        } catch (err) {
            console.log(err, 'error')
            return false
        }
    }

    const usersPaginated = computed(() => {
        let items = []

        if (searchInput.value.length > 2) {
            items = users.value.filter((element) => {
                return element.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0
                    || element.email.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0
            })
        } else {
            items = users.value.slice((page.value - 1) * itemsPerPage.value, ((page.value - 1) * itemsPerPage.value) + itemsPerPage.value)
        }


        return items
    })

    function nextPage() {
        if (page.value > users.value.length / itemsPerPage.value) return;

        page.value++
    }

    function prevPage() {
        if ((page.value - 1) < 1) return;

        page.value--
    }

    function changePage(value) {
        page.value = value
    }

    function filter(event) {
        const field = event.target.value
        users.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function sortDirection(arg) {
        orderFilter.value = arg
        users.value.reverse()
    }

    function getCurrentUser(id) {
        if (id != 'new') {
            return currentUser.value = users.value.find(el => el.id == id)
    
        } else {
            return currentUser.value = {
                name: '',
                email: '',
                id: 'new'
            }
        }
    }

    const setUserCurrent = (user) => {
        currentUser.value = user
    }

    async function submitForm(form) {
        const formData = new FormData()
        formData.append('name', form.name)
        formData.append('email', form.email)
        formData.append('role', form.role)
        formData.append('password', form.password)

        try {
            const { data } = await server.post('/users', formData)
            const { status, results } = data

            if (!status)
                return false

            users.value.unshift(results.data)
            swalToast(`El usuario ${form.name} ha sido creado correctamente.`)
        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
        return true
    }
    async function update() {
        const formData = new FormData()
        formData.append('name', currentUser.value.name)
        formData.append('email', currentUser.value.email)
        formData.append('role', currentUser.value.role)
        formData.append('user_id', currentUser.value.id)
        console.log(currentUser.value.password)
        if (currentUser.value.password != undefined) formData.append('password', currentUser.value.password)
        try {
            const { data } = await server.post('/users/update', formData)
            const { status } = data

            if (!status)
                return false

            const index = users.value.findIndex(item => item.id == currentUser.value.id)
            users.value[index] = currentUser.value
            swalToast(`El usuario ${currentUser.value.name} ha sido modificado correctamente.`)

        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
        return true
    }

    async function updateDisponibility() {
        //console.log(currentUser.value.disponibility.data)
        const formData = new FormData()
        formData.append('user_id', currentUser.value.id)
        formData.append('lunes', currentUser.value.disponibility.data.lunes ? 1 : 0)
        formData.append('martes', currentUser.value.disponibility.data.martes ? 1 : 0)
        formData.append('miercoles', currentUser.value.disponibility.data.miercoles ? 1 : 0)
        formData.append('jueves', currentUser.value.disponibility.data.jueves ? 1 : 0)
        formData.append('viernes', currentUser.value.disponibility.data.viernes ? 1 : 0)
        formData.append('sabado', currentUser.value.disponibility.data.sabado ? 1 : 0)
        formData.append('domingo', currentUser.value.disponibility.data.domingo ? 1 : 0)
        try {
            const { data } = await server.post('/users/update/disponibility', formData)
            const { status } = data

            if (!status) return false

            const index = users.value.findIndex(item => item.id == currentUser.value.id)
            users.value[index] = currentUser.value
            swalToast("Disponibilidad actualizada correctamente.")

        } catch (err) {
            console.log(err)
            swalToast('No se ha podido modificar la disponibilidad')

        }
        return true
    }

    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas dar de baja este usuario?", "Una vez eliminado no podrá recuperarse.", "Eliminar", "Cancelar", "warning")
        if (!confirmed) return;

        try {
            const { data } = await server.delete(`users/${id}`)
            const { status } = data

            if (!status)
                return false

            const index = users.value.findIndex(el => el.id == id)
            users.value.splice(index, 1)

            swalToast("El usuario ha sido eliminado correctamente.")
            return true
        } catch (err) {
            console.log(err, 'error')
            swalToast("No se ha podido eliminar el usuario.")
            return false
        }
    }

    async function closeCurrentUser() {

        if (planningUser.value){
            const confirmed = await swalQuestionCustom("¿Deseas cerrar la edición del usuario?", "Una vez cerrada no podrá modificarse.", "Cerrar", "Cancelar", "warning")
            if (!confirmed) return;
            planningUser.value = false
            router.push({ name: 'users' })
        }else{
            router.push({ name: 'users' })
        }
    }

    const getSessionsTrainerCalendar = async(clubId) => {
        try {
            const { data } = await server.get(`/sessions/trainer/${clubId}`)
            const { status, results } = data

            if(!status)
                return false
            
            currentUser.value.sessions.data = results
            return true
        } catch(err) {
            swalToast(err.response.data, 'error')
            return false
        }
    }

    return {
        users,
        usersPaginated,
        currentUser,
        searchInput,
        orderFilter,
        page,
        itemsPerPage,
        planningUser,
        changePage,
        destroy,
        update,
        filter,
        getCurrentUser,
        getSessionsTrainerCalendar,
        getUsers,
        nextPage,
        prevPage,
        sortDirection,
        submitForm,
        updateDisponibility,
        closeCurrentUser,
        setUserCurrent
    }
})