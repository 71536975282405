<template>
    <div v-if="achievement">
        <div class="modal-backdrop fade show"></div>
        <div class="modal modal fade show d-block"  tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-center d-flex flex-column align-items-center justify-content-center">
                        <h1 class="mb-5 text-monospace">Has desbloqueado un logro nuevo!</h1>

                        <img :src="`${baseURL}/${achievement.image}`" alt="">
                        <p class="fw-bold mb-1 text-monospace">{{ achievement.title }}</p>
                        <p class="text-monospace">{{ achievement.descr }}</p>
                        <p class="text-entrna text-monospace h1 mb-5" v-if="achievement.points > 0">+{{achievement.points}}pts</p>

                        <button class="btn btn-outline-dark" @click="clearAll">
                            <i class="bi bi-chevron-left"></i>
                            Volver al trabajo!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <canvas id="confetti" :class="confettiStatus == 'render' ? 'd-block' : 'd-none'"></canvas>
</template>

<script setup>
import { useGlobalStore } from '@/stores/globalStore';
import { onMounted, ref, watch } from 'vue';
import ConfettiGenerator from "confetti-js";

const globalStore = useGlobalStore()
const confetti = ref()
const confettiStatus = ref('clear')
const baseURL = process.env.VUE_APP_BASE_URL
const achievement = ref(null)

onMounted(async () => {
    const confettiSettings = { target: 'confetti' };
    confetti.value = new ConfettiGenerator(confettiSettings);
});

watch( () => globalStore.getNewAchievement, (newVal, oldVal) => {
    if(!newVal)
        return

    achievement.value = newVal
    setConfetti('render')
    setTimeout( () => setConfetti('clear'), 3000)
})

function clearAll() {
    achievement.value = null
}

function setConfetti(status) {
    if(status === 'clear') {
        confetti.value.clear()
        return confettiStatus.value = 'clear'
    }

    if(status === 'render') {
        confetti.value.render()
        return confettiStatus.value = 'render'
    }   
}
</script>