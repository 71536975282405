<template>
    <div v-if="false">
            <!-- Trainer -->
            <div class="d-flex justify-content-between"
                v-if="globalStore.auth?.data?.role == 'trainer' || globalStore.auth?.data?.role == 'admin'">
                <div class="footer-item text-center mt-2" v-if="globalStore.auth?.data?.role == 'admin'">
                    <router-link class="footer-item-link text-center pointer text-muted" :to="{ name: 'achievements' }">
                        <i class="bi bi-award"></i>
                        <span class="mt-1">Logros</span>
                    </router-link>
                </div>
                <div class="footer-item text-center mt-2">
                    <a class="footer-item-link text-center pointer text-muted" @click="globalStore.logOut()">
                        <i class="bi bi-escape"></i>
                        <span class="mt-1">Salir</span>
                    </a>
                </div>
            </div>
    </div>

    <footer class="d-lg-none navigation" v-if="globalStore.status == 'authorised'">
        <ul>
            <li v-for="route in routes.filter(el => el.roles.includes(globalStore.auth?.data?.role))" :key="route.name">
                <router-link :to="{ name: route.name }">
                    <span class="icon">
                        <i class="bi" :class="route.icon"></i>
                    </span>
                    <span class="text">{{ route.label }}</span>
                </router-link>
            </li>
            <div class="indicator"></div>
        </ul>
    </footer>
</template>

<script setup>
import { useGlobalStore } from "@/stores/globalStore";
const globalStore = useGlobalStore();

const routes = [
    {
        roles: ['athlete', 'trainer', 'admin'],
        name: 'home',
        icon: 'bi bi-house-fill',
        label: 'Inicio'
    },
    {
        roles: ['athlete'],
        name: 'calendar',
        icon: 'bi bi-calendar-week-fill',
        label: 'Resumen'
    },
    {
        roles: ['athlete'],
        name: 'my-goals',
        icon: 'bi bi-trophy-fill',
        label: 'Objetivos'
    },
    {
        roles: ['athlete'],
        name: 'stats',
        icon: 'bi bi-bar-chart-fill',
        label: 'Estadísticas'
    },
    {
        roles: ['admin', 'trainer'],
        name: 'users',
        icon: 'bi bi-person-fill',
        label: 'Atletas'
    },
    {
        roles: ['admin', 'trainer'],
        name: 'sessions',
        icon: 'bi bi-clipboard2-data-fill',
        label: 'Sesiones'
    },
    {
        roles: ['admin', 'trainer'],
        name: 'goals',
        icon: 'bi bi-trophy-fill',
        label: 'Objetivos'
    },
    {
        roles: ['admin'],
        name: 'achievements',
        icon: 'bi bi-award-fill',
        label: 'Logros'
    },
    {
        roles: ['athlete', 'trainer', 'admin'],
        name: 'profile',
        icon: 'bi bi-person-fill',
        label: 'Mi perfil'
    },
]

</script>

<style scoped>
.filters-container {
    position: fixed;
    bottom: 0px;
    min-height: 400px;
    background-color: white;
    width: calc(100% - 20px);
    padding: 20px;
    margin: 10px;
}

footer {
    z-index: 99;
    bottom: 10px;
    position: fixed;
    height: 70px;
    margin-left: 10px;
    width: calc(100% - 20px);
}

.white-space {
    width: 100px;
}

.position-absolute {
    top: -25px;
    background-color: #fff;
    border: 2px solid #ed8600;
    padding: 10px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.position-absolute span,
.position-absolute i {
    color: #ed8600;
}

.footer-item {
    font-size: 24px;
}

.footer-item-link span {
    font-size: 12px;
    display: block;
    line-height: 6px;
}

.footer-item-link {
    text-decoration: none;
}

.btn-center {
    height: 60px;
    width: 60px;
    font-size: 26px;
    text-align: center;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -60%);
}
/* New Style navigation */
.icon, .text {
  color: #222327;
}

.navigation {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

ul {
  display: flex;
  width: 350px;
  padding: 0;
}

li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
}

li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

a .icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
}

li a.router-link-active .icon {
  transform: translateY(-35px);
}

a .text {
  position: absolute;
  font-weight: 400;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}

li a.router-link-active .text {
  opacity: 1;
  transform: translateY(10px);
}

.indicator {
  display: none;
  position: absolute;
  top: -50%;
  width: 70px;
  height: 70px;
  background: #edba6d;
  box-sizing: border-box;
  border-radius: 50%;
  border: 6px solid #ffffff;
  transition: 0.5s;
}

.indicator::before,
.indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background: transparent;
}

.indicator::before {
  left: -22px;
  border-top-right-radius: 20px;
}

.indicator::after {
  right: -22px;
  border-top-left-radius: 20px;
}

li:has(a.router-link-active)~.indicator {
  display: inherit!important;
  --x: 0;
  transform: translateX(var(--x));
}

/* li:not(:has(a.router-link-active))~.indicator {
    display: none!important;
} */

li:nth-child(1):has(a.router-link-active)~.indicator { --x: 0px; }
li:nth-child(2):has(a.router-link-active)~.indicator { --x: 70px; }
li:nth-child(3):has(a.router-link-active)~.indicator { --x: 140px; }
li:nth-child(4):has(a.router-link-active)~.indicator { --x: 210px; }
li:nth-child(5):has(a.router-link-active)~.indicator { --x: 280px; }
li:nth-child(6):has(a.router-link-active)~.indicator { --x: 350px; }
li:nth-child(7):has(a.router-link-active)~.indicator { --x: 420px; }

</style>