import { useGlobalStore } from "../../stores/globalStore";
import { swalToast } from '../../helpers/swal'

const isTrainer = async( to, from, next) => {
    const globalStore = useGlobalStore()
    await globalStore.checkAuth ()

    if (globalStore.status === 'unauthorised'){
        swalToast("Debes iniciar sesión para acceder a esta sección", 'error')
        return next({ name: 'login' })
    }

    if (globalStore.auth?.data?.role !== 'trainer' && globalStore.auth?.data?.role !== 'admin') {
        swalToast("No tienes privilegios para acceder a esta sección.", 'error')
        return next({ name: 'home' })
    }

    return next()
}

export default isTrainer