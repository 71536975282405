<template>
    <div class="profile-image">
        <img v-if="!avatar" src="/assets/icons/desconocido.png" :width="size" :height="size"/>
        <img v-else :src="`${baseURL}/${avatar}`" :width="size" :height="size" class="has-avatar"/>
    </div>
</template>

<script setup>
const props = defineProps({
    avatar: {
        defaukt: null
    },
    size: {
        default: 20
    }
})
const baseURL = process.env.VUE_APP_BASE_URL;

</script>

<style scoped>
img {
    object-fit:cover;
    border-radius:100%;
    object-position: center;
}

img.has-avatar {
    background-image: url('@/assets/images/desconocido.png');
    background-size: cover;
}
</style>