import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'
import { swalToast } from '@/helpers/swal'
import { useLocalStorage } from "@vueuse/core"
import server from '../api/server'

export const useGlobalStore = defineStore('globalStore', () => {
    const router = useRouter()
    const auth = ref(false)
    const status = ref('unauthorised')
    const bearerToken = useLocalStorage('token', '')
    const isLoading = ref(true)
    const newAchievement = ref(null)

    /**
     * Retorna un booleano según el estado de la autenticación.
     * En caso de tener un token en localstorage hará un inicio de sesion
     * automatico.
     * 
     * @return bool
     */
     const checkAuth = async() => {
        if(status.value === 'unauthorised') {
            if(bearerToken.value === null || bearerToken.value == '') return false

            try {
                const { data } = await server.get('/auth/check')

                if(!data.status) 
                    return false

                setAuth(data.user, 'authorised')
            } catch(err) {
                bearerToken.value = null
                return false
            }
        }
        return true
	}

    const submitLogin = async({email, password}) => {
        try {
            const { data } = await server.post('/auth/login', {email, password})
            const { status, token, message, user } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }
            
            bearerToken.value = token.accessToken
            router.push({ name: 'home' })
        } catch(err) {
            swalToast(err.response.data.data.message, 'error')
            return false
        }

        return true
    } 

    const logOut = () => {
        bearerToken.value = null
        setAuth({}, 'unauthorised')
        router.push({ name: 'login'} )
    }

    const setAuth = async(authArg, statusArg) => {
		auth.value      = authArg
		status.value    = statusArg
	}

    const updateAvatar = async(file) => {
        const formData = new FormData()
        formData.append('avatar', file)

        try {
            const { data } = await server.post('/auth/avatar', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
            const { status, message, results } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }
            swalToast('Su avatar ha sido actualizado con éxito.', 'success')
            auth.value.data.avatar = message
            return true 
        } catch(err) {
            swalToast(err.response.data.data.message, 'error')
            return false
        }
    }

    const setIsLoading = (value) => {
        isLoading.value = value
    }

    const setNewAchievement = (args) => {
        newAchievement.value = args
        auth.value.data.achievements.data.push(args)
    }

    async function checkAssist(id, action) {
        try {
            const { data } = await server.post(`/users/sessions/check/${id}/${action}`)
            const { status, message, results } = data

            if (!status)
                return false

            if(results.achievement)
                setNewAchievement(results.achievement)

            swalToast(message)
            const index = auth.value.data.sessions.data.findIndex(el => el.user_session_id === results.session.id)
            auth.value.data.sessions.data[index].has_assisted = results.session.has_assisted 

            return true
        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
                return false
            });
        }
    }
    
    return { 
        auth: computed( () => auth.value ), 
        status: computed( () => status.value ),
        isLoading: computed( () => isLoading.value ), 
        getToken: computed( () => bearerToken.value ),
        getNewAchievement: computed( () => newAchievement.value ),
        checkAssist,
        setNewAchievement,
        updateAvatar,
        logOut,
        checkAuth,
        submitLogin,
        setIsLoading,
    }
})
