<template>
    <nav class="d-none d-md-block navbar py-0 navbar-expand-lg navbar-light bg-light shadow-lg">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{ name: 'home' }">
                <img class="logo_navbar"
                src="@/assets/logo.webp"
                alt=""
                height="30"
                />
            </router-link>

            <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
                <header-link :items="navbar.filter(el => el.role == globalStore.auth?.data?.role)" @on-change="selectRoute" />

                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle d-flex align-items-center justify-content-end" 
                            href="#" id="navbarDarkDropdownMenuLink" 
                            role="button" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false">
                            <user-avatar-badge :avatar="globalStore.auth?.data?.avatar" :name="globalStore.auth?.data?.name" />
                            <span class="mx-1">{{ globalStore.auth?.data?.name }}</span>
                        </a>
                        <ul class="dropdown-menu m-0" aria-labelledby="navbarDarkDropdownMenuLink" style="left:-20px">
                            <li class="pointer">
                                <a class="dropdown-item fw-light" @click="globalStore.logOut">
                                  <i class="bi bi-box-arrow-left"></i> Cerrar sesión
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { ref } from 'vue'
import { useGlobalStore } from '../../stores/globalStore'
import { useRouter } from 'vue-router'
import HeaderLink from '../common/HeaderLink.vue'
import UserAvatarBadge from '../users/UserAvatarBadge.vue'

const globalStore = useGlobalStore()
const router = useRouter()

const selectRoute = (item) => {
    router.push({ name: item.route })
}

const navbar = ref([
    {
        route: 'home',
        label: 'Inicio',
        role: 'admin',
    },
    {
        route: 'users',
        label: 'Usuarios',
        role: 'admin',
    },
    {
        route: 'sessions',
        label: 'Sesiones',
        role: 'admin',
    },
    {
        route: 'clubs',
        label: 'Clubs',
        role: 'admin',
    },
    {
        route: 'tests',
        label: 'Tests',
        role: 'admin',
    },
    {
        route: 'notifications',
        label: 'Notificaciones',
        role: 'admin',
    },
    {
        route: 'achievements',
        label: 'Logros',
        role: 'admin',
    },
    {
        route: 'home',
        label: 'Inicio',
        role: 'athlete',
    },
    {
        route: 'calendar',
        label: 'Planificación',
        role: 'athlete',
    },
    {
        route: 'stats',
        label: 'Estadísticas',
        role: 'athlete',
    },
    {
        route: 'profile',
        label: 'Mi perfil',
        role: 'athlete',
    },
    {
        route: 'home',
        label: 'Inicio',
        role: 'trainer',
    },
    {
        route: 'users',
        label: 'Atletas',
        role: 'trainer',
    },
    {
        route: 'sessions',
        label: 'Sesiones',
        role: 'trainer',
    },
    {
        route: 'goals',
        label: 'Objetivos',
        role: 'trainer',
    },
    {
        route: 'tests',
        label: 'Tests',
        role: 'trainer',
    },
])
</script>

<style scoped>
    .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
        color: rgb(233 94 6)!important;
        font-weight: bold!important;
    }
    .navbar{
        background-color: rgb(77, 77, 88) !important;
    }
    .logo_navbar{
        margin-left: 50px;
    }
    .nav-link{
        color: rgb(154, 154, 177) !important;
    }
</style>