import isAuth from './guards/isAuth'

export default [
    {
        path: '/home',
        name: 'home',
        beforeEnter: [isAuth],
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/disponibility',
        name: 'disponibility',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/DisponibilityView.vue')
    },
    {
        path: '/calendar',
        name: 'calendar',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/CalendarView.vue')
    },
    {
        path: '/stats',
        name: 'stats',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/StatsView.vue')
    },
    {
        path: '/account/profile',
        name: 'profile',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/ProfileView.vue')
    },
    {
        path: '/account',
        name: 'account',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/AccountView.vue')
    },
    {
        path: '/account/goals',
        name: 'my-goals',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/GoalsView.vue')
    },
    {
        path: '/achievements',
        name: 'my-achievements',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/AchievementsView.vue')
    },
    {
        path: '/goals/new',
        name: 'goals.create',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/CreateGoalView.vue')
    },
    {
        path: '/injuries',
        name: 'my-injuries',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/InjuriesView.vue')
    },
    {
        path: '/tests',
        name: 'my-tests',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/TestView.vue')
    },
    {
        path: '/tests/:id',
        name: 'my-tests-single',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/TestView.vue')
    },
    {
        path: '/tests/:id',
        name: 'my-tests-single',
        beforeEnter: [isAuth],
        component: () => import('../views/athlete/TestResultsView.vue'),
        props: (route) => {
            return {
                id: route.params.id
            }
        }
    },
]