import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { swalToast, swalQuestionCustom,swalError } from '../helpers/swal'
import { useGlobalStore } from './globalStore'
import server from '../api/server'

export const useSessionsStore = defineStore('sessionsStore', () => {
    const globalStore = useGlobalStore()
    const dataSessions = ref([])
    const currentSession = ref({})
    const isLoading = ref(true)
    const searchInput = ref('')
    const page = ref(1)
    const itemsPerPage = ref(15)
    const orderFilter = ref('asc')

    // Filter variables
    const type = ref('all')
    const intensity = ref('all')
    const duration = ref('all')
    const rpe = ref('all')
    const distance = ref('all')

    const sessionsPaginated = computed(() => {
        let items = dataSessions.value

        if (searchInput.value.length > 2) {
            items = dataSessions.value.filter((element) => {
                return element.title.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0
            })

        } else {
            if(type.value != 'all') {
                items = items.filter((el) => el.type == type.value)
            }

            if(intensity.value != 'all') {
                items = items.filter((el) => el.intensity == intensity.value)
            }

            if(duration.value != 'all') {
                items = items.filter((el) => (el.duration >= parseInt(duration.value.split(",")[0])) && (el.duration <= parseInt(duration.value.split(",")[1])))
            }

            if(distance.value != 'all') {
                items = items.filter((el) => (el.distance >= parseInt(distance.value.split(",")[0])) && (el.distance <= parseInt(distance.value.split(",")[1])) && (el.type_distance == distance.value.split(",")[2]))
            }

            if(rpe.value != 'all') {
                items = items.filter((el) => (el.rpe >= parseInt(rpe.value.split(",")[0])) && (el.rpe <= parseInt(rpe.value.split(",")[1])))
            }

            return items.slice((page.value - 1) * itemsPerPage.value, ((page.value - 1) * itemsPerPage.value) + itemsPerPage.value)
        }

        return items
    })

    const getSessionsLength = computed(() => {
        let arr = dataSessions.value

        if(type.value != 'all')
            arr = arr.filter((el) => el.type == type.value)

        if(intensity.value != 'all')
            arr = arr.filter((el) => el.intensity == intensity.value)  

        if(duration.value != 'all') {
            arr = arr.filter((el) => (el.duration >= parseInt(duration.value.split(",")[0])) && (el.duration <= parseInt(duration.value.split(",")[1])))
        }

        if(distance.value != 'all') {
            arr = arr.filter((el) => (el.distance >= parseInt(distance.value.split(",")[0])) && (el.distance <= parseInt(distance.value.split(",")[1])) && (el.type_distance == distance.value.split(",")[2]))
        }

        if(rpe.value != 'all') {
            arr = arr.filter((el) => (el.rpe >= parseInt(rpe.value.split(",")[0])) && (el.rpe <= parseInt(rpe.value.split(",")[1])))
        }

        return arr.length 
    })

    async function getSessions() {
        isLoading.value = true
        try {
            const { data } = await server.get(`/sessions`)
            const { status, results } = data

            if (!status) {
                console.log(err, 'error')
                return false
            }

            dataSessions.value = results.data.reverse()
            isLoading.value = false
        } catch (err) {
            console.log(err, 'error')
            return false
        }
    }

    function sortDirection(arg) {
        orderFilter.value = arg
        dataSessions.value.reverse()
    }

    function nextPage() {
        if (page.value > dataSessions.value.length / itemsPerPage.value) return;
        page.value++
    }

    function prevPage() {
        if ((page.value - 1) < 1) return;
        page.value--
    }

    function changePage(value) {
        page.value = value
    }

    function filter(event) {
        const field = event.target.value
        dataSessions.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function setType(arg) {
        page.value = 1
        type.value = arg
    }

    function setIntensity(arg) {
        page.value = 1
        intensity.value = arg
    }

    function setDuration(arg) {
        page.value = 1
        duration.value = arg
    }

    function setDistance(arg) {
        page.value = 1
        distance.value = arg
    }

    function setRPE(arg) {
        page.value = 1
        rpe.value = arg
    }

    function getCurrentSession(id) {
        if (id != 'new') {
            currentSession.value = dataSessions.value.find(el => el.id == id)
        } else {
            currentSession.value = {
                description: '',
                duration: '',
                intensity: '',
                rpe: 0,
                title: '',
                type: '',
                distance: '',
                type_distance: ''
            }
        }
    }

    async function attachToUsers(payload) {
        try {
            const { data } = await server.post('/sessions/attach', payload)
            const { status, message, results } = data

            if (!status)
                return false

            swalToast(message)
            if(results)
                return results
            
            return true
        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
                return false
            });
        }
    }

    async function attachToUser(payload) {
        try {
            const { data } = await server.post('/sessions/users/assistence', payload)
            const { status, message, results } = data

            if (!status)
                return false

            swalToast(message)
            if(results)
                return results
            
            return true
        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
                return false
            });
        }
    }

    async function saveUserSession(payload) {
        try {
            const { data } = await server.post('/users/sessions/assist', payload)
            const { status, message } = data

            if (!status)
                return false

            swalToast(message)
            return true
        } catch (err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
                return false
            });
        }
    }

    async function submitForm(form) {
        if(globalStore.auth.data.role === 'trainer')
            form.user_id = globalStore.auth.data.id
        
        try {
            const { data } = await server.post('/sessions', form)
            const { status, results } = data

            if (!status)
                return false

            dataSessions.value.unshift(results.data)
            swalToast(`La sesión ${form.title} ha sido creada correctamente.`)
        } catch (err) {
            console.log(err)
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
        return true
    }

    async function update() {        
        try {
            const { data } = await server.post('/sessions/update', currentSession.value)
            const { status } = data

            if (!status)
                return false

            const index = dataSessions.value.findIndex(item => item.id == currentSession.value.id)
            dataSessions.value[index] = currentSession.value
            swalToast(`La sesión ${currentSession.value.title} ha sido modificada correctamente.`)

        } catch (err) {
            console.log(err)
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
        return true
    }

    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar esta sesión?", "Una vez eliminada no podrá recuperarse.", "Eliminar", "Cancelar", "warning")
        if (!confirmed) return;

        try {
            const { data } = await server.delete(`sessions/${id}`)
            const { status } = data

            if (!status)
                return false

            const index = dataSessions.value.findIndex(el => el.id == id)
            dataSessions.value.splice(index, 1)

            swalToast("La sesión ha sido eliminada correctamente.")
            return true
        } catch (err) {
            console.log(err, 'error')
            swalError("No se ha podido eliminar la sesión.", err.status)
            return false
        }
    }

    async function getAssistents({date, session_id}) {
        try {
            const { data } = await server.post(`/users/sessions/assistents`, {date, session_id})
            const { status, results, message } = data

            if (!status) {
                console.log(message, 'error')
                return null
            }

            return results.sort((a, b) => b.has_assisted - a.has_assisted)
        } catch (err) {
            console.log(err, 'error')
            return null
        }
    }

    return {
        currentSession,
        dataSessions,
        getSessionsLength,
        getType: computed(() => type.value),
        getIntensity: computed(() => intensity.value),
        getDuration: computed(() => duration.value),
        getDistance: computed(() => distance.value),
        getRPE: computed(() => rpe.value),
        isLoading,
        itemsPerPage,
        orderFilter,
        page,
        searchInput,
        sessionsPaginated,
        attachToUser,
        attachToUsers,
        changePage,
        destroy,
        filter,
        getAssistents,
        getCurrentSession,
        getSessions,
        nextPage,
        prevPage,
        saveUserSession,
        setType,
        setIntensity,
        setDuration,
        setRPE,
        setDistance,
        sortDirection,
        submitForm,
        update,
    }
})
