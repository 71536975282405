import { createRouter, createWebHistory } from 'vue-router'
import authRouter from './authRouter'
import adminRouter from './adminRouter'
import isNotAuth from './guards/isNotAuth'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    routes: [
        ...authRouter,
        ...adminRouter,
        {
            path: '/',
            name: 'welcome',
            beforeEnter: [isNotAuth],
            component: () => import('../views/WelcomeView.vue')
        },
        {
            path: '/auth/login',
            name: 'login',
            beforeEnter: [isNotAuth],
            component: () => import('../views/auth/LoginView.vue')
        },
        {
            path: '/auth/register',
            name: 'register',
            beforeEnter: [isNotAuth],
            component: () => import('../views/auth/RegisterView.vue')
        },
        {
            name: '404',
            path: '/:catchAll(.*)',
            redirect: '/'
        },
    ]
})

export default router
