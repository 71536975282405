import { useGlobalStore } from '@/stores/globalStore';
import axios from 'axios'

const serverURL = process.env.VUE_APP_API_URL

const server = axios.create({
    baseURL: serverURL,
});

server.interceptors.request.use((config) => {
    const store = useGlobalStore()

    if (store.getToken) {
        config.headers.Authorization = `Bearer ${store.getToken}`
    }
    config.headers.Accept = "application/json";
    
    return config
});


export default server