import { useGlobalStore } from "../../stores/globalStore";
import { swalToast } from '../../helpers/swal'

const isAuth = async( to, from, next) => {
    const globalStore = useGlobalStore()
    await globalStore.checkAuth ()

    if (globalStore.status === 'unauthorised'){
        swalToast("Debes iniciar sesión para acceder a esta sección", 'error')
        return next({ name: 'login' })
    }

    return next()
}

export default isAuth